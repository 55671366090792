<template>
  <div class="order">
    <div v-show="mfcSelected !== 0" >
      <nut-cell
        :title="'面粉厂名字:' + mfcSelectName"
        @click="show = true"
        is-link
      />
      <nut-picker v-model:visible="show" :columns="mfcList" title="面粉厂选择" @change="changeMfc" />
    </div>
    <nut-tabs v-model="tabValue" @change="changeOrderStatus">
      <nut-tabpane title="待发货" :pane-key="4">
        <div v-if="tabValue  ==4">
          <div class="orderList" v-for="(item, index) in orderList" :key="index">
            <div>
              <span class="mfcName">{{item.mfc_name}}</span>
              <span class="time">{{ item.add_time }}</span>
              <span class="orderStatus">{{ item.order_status_name }}</span>
            </div>
            <div class="clearBox"></div>
            <div class="goodsList" v-for="(goods ,index) in item.order_detail" :key="index">
              <div class="goods">
                <div style="float: left">
                  <img :src="goods.goods_pic" alt="" class="goodsImg" >
                </div>
                <div class="desc">
                  <div class="goodsName">{{ goods.goods_name }}</div>
                  <div class="goodsDesc" v-if="goods.goods_type == 2">
                    <span>数量:{{ goods.goods_amount }}</span>
                    <!--                  <span style="margin-left: 0.9rem"> 价格:{{ goods.goods_price/100 }} </span>-->
                  </div>
                  <!--非标品-->
                  <div class="goodsDesc" v-else>
                    <span>数量:{{ goods.goods_amount/500 }}斤</span>
                    <!--                  <span style="margin-left: 0.9rem"> 价格:{{ goods.goods_price/100 }} </span>-->
                  </div>
                </div>

              </div>
            </div>
            <div style="margin-left: .5rem;">订单号：{{ item.order_sn }}</div>
            <div class="clearBox payFee">
              <!-- //订单类型 1.小麦兑换 2.货币购买 3.积分兑换 4.小麦预存 5.面粉支取-->
              <span style="margin-right: 1rem" v-if="item.order_type == 1 ">支付：小麦{{ item.wheat_weight/500 }}斤</span>
              <span style="margin-right: 1rem" v-else-if="item.order_type == 2 ">支付：￥{{ item.pay_fee/100 }}</span>
              <span style="margin-right: 1rem" v-else-if="item.order_type == 3 ">积分：{{ item.points_num }}</span>
            </div>
          </div>
          <div style="text-align: center; margin-top: 0.3rem">
            <nut-pagination class="pageBottom"  v-show="totalPage" v-model="currentPage" :page-count="totalPage" mode="simple" @change="pageChange" />
          </div>
        </div>
      </nut-tabpane>
      <nut-tabpane title="待收货" :pane-key="5">
        <div v-if="tabValue  ==5">
          <div class="orderList" v-for="(item, index) in orderList" :key="index">
            <div>
              <span class="mfcName">{{item.mfc_name}}</span>
              <span class="time">{{ item.add_time }}</span>
              <span class="orderStatus">{{ item.order_status_name }}</span>
            </div>
            <div class="clearBox"></div>
            <div class="goodsList" v-for="(goods ,index) in item.order_detail" :key="index">
              <div class="goods">
                <div style="float: left">
                  <img :src="goods.goods_pic" alt="" class="goodsImg" >
                </div>
                <div class="desc">
                  <div class="goodsName">{{ goods.goods_name }}</div>
                  <div class="goodsDesc" v-if="goods.goods_type == 2">
                    <span>数量:{{ goods.goods_amount }}</span>
                    <!--                  <span style="margin-left: 0.9rem"> 价格:{{ goods.goods_price/100 }} </span>-->
                  </div>
                  <!--非标品-->
                  <div class="goodsDesc" v-else>
                    <span>数量:{{ goods.goods_amount/500 }}斤</span>
                    <!--                  <span style="margin-left: 0.9rem"> 价格:{{ goods.goods_price/100 }} </span>-->
                  </div>
                </div>

              </div>
            </div>
            <div style="margin-left: .5rem;">订单号：{{ item.order_sn }}</div>
            <div class="clearBox payFee">
              <!-- //订单类型 1.小麦兑换 2.货币购买 3.积分兑换 4.小麦预存 5.面粉支取-->
              <span style="margin-right: 1rem" v-if="item.order_type == 1 ">支付：小麦{{ item.wheat_weight/500 }}斤</span>
              <span style="margin-right: 1rem" v-else-if="item.order_type == 2 ">支付：￥{{ item.pay_fee/100 }}</span>
              <span style="margin-right: 1rem" v-else-if="item.order_type == 3 ">积分：{{ item.points_num }}</span>
            </div>
          </div>
          <div style="text-align: center; margin-top: 0.3rem">
            <nut-pagination class="pageBottom"  v-show="totalPage" v-model="currentPage" :page-count="totalPage" mode="simple" @change="pageChange" />
          </div>
        </div>
      </nut-tabpane>
      <nut-tabpane title="全部" :pane-key="10">
        <div v-if="tabValue  ==10">
          <div class="orderList" v-for="(item, index) in orderList" :key="index">
            <div>
              <span class="mfcName">{{item.mfc_name}}</span>
              <span class="time">{{ item.add_time }}</span>
              <span class="orderStatus">{{ item.order_status_name }}</span>
            </div>
            <div class="clearBox"></div>
            <div class="goodsList" v-for="(goods ,index) in item.order_detail" :key="index">
              <div class="goods">
                <div style="float: left">
                  <img :src="goods.goods_pic" alt="" class="goodsImg" >
                </div>
                <div class="desc">
                  <div class="goodsName">{{ goods.goods_name }}</div>
                  <div class="goodsDesc" v-if="goods.goods_type == 2">
                    <span>数量:{{ goods.goods_amount }}</span>
                    <!--                  <span style="margin-left: 0.9rem"> 价格:{{ goods.goods_price/100 }} </span>-->
                  </div>
                  <!--非标品-->
                  <div class="goodsDesc" v-else>
                    <span>数量:{{ goods.goods_amount/500 }}斤</span>
                    <!--                  <span style="margin-left: 0.9rem"> 价格:{{ goods.goods_price/100 }} </span>-->
                  </div>
                </div>

              </div>
            </div>
            <div style="margin-left: .5rem;">订单号：{{ item.order_sn }}</div>
            <div class="clearBox payFee">
              <!-- //订单类型 1.小麦兑换 2.货币购买 3.积分兑换 4.小麦预存 5.面粉支取-->
              <span style="margin-right: 1rem" v-if="item.order_type == 1 ">支付：小麦{{ item.wheat_weight/500 }}斤</span>
              <span style="margin-right: 1rem" v-else-if="item.order_type == 2 ">支付：￥{{ item.pay_fee/100 }}</span>
              <span style="margin-right: 1rem" v-else-if="item.order_type == 3 ">积分：{{ item.points_num }}</span>
            </div>
          </div>
          <div style="text-align: center; margin-top: 0.3rem">
            <nut-pagination class="pageBottom"  v-show="totalPage" v-model="currentPage" :page-count="totalPage" mode="simple" @change="pageChange" />
          </div>
        </div>
      </nut-tabpane>
    </nut-tabs>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useOptionsConfig } from "../../hooks/optionsConfig";
import * as orderApi from "../../api/order";
import { Toast } from "@nutui/nutui";
import { OrderStatusNameMap } from "../../utils/map";
import dayjs from "dayjs";

export default {
  setup() {
    const show = ref(false);
    const mfcSelectName = ref("");
    const tabValue = ref(4);//0 下单完成 1 完成（所有面粉已经取完）2 订单未完成（有余面） 3:订单取消 4.待发货  5.待收货
    const { mfcList, mfcSelected } = useOptionsConfig();
    const changeMfc = ({ selectedValue }) => {
      mfcSelected.value = selectedValue[0];
    };
    const totalPage = ref(0);
    const currentPage = ref(0);
    const getMfcNameByMfcId = (mfcId) => {
      var mfcName = "";
      var mfcListLen = mfcList.value.length;
      for( var i=0;i<mfcListLen;i++ ){
        if( mfcList.value[i].value == mfcId ){
          mfcName = mfcList.value[i].text;
        }
      }
      return mfcName;
    };
    watch(mfcSelected, (mfcId) => {
      mfcSelectName.value = getMfcNameByMfcId(mfcId);
      getOrderList(1);
    });
    const orderList = ref([]);
    const orderStatusName = ref(OrderStatusNameMap);
    const getOrderList = async (page) => {
      const res = await orderApi.list({mfc_id:mfcSelected.value,order_status:tabValue.value,page_size:10,page: page});
      if(res.code == 0){
        res.data.list.forEach((item,index) => {
          res.data.list[index].order_status_name= OrderStatusNameMap[item.order_status] == undefined ? "" :OrderStatusNameMap[item.order_status];
          res.data.list[index].add_time= dayjs(item.add_time * 1000).format("YYYY-MM-DD HH:mm");
        });
        orderList.value = res.data.list;
        totalPage.value = res.data.page.page_total;
        currentPage.value = res.data.page.page_index;
      }else{
        Toast.text(res.message);
      }
    };
    const changeOrderStatus = () =>{
      getOrderList(1);
    };
    const pageChange = (val) => {
      getOrderList(val);
    };
    return {
      changeMfc,
      changeOrderStatus,
      pageChange,
      show,
      mfcList,
      mfcSelected,
      mfcSelectName,
      tabValue,
      orderList,
      orderStatusName,
      currentPage,
      totalPage,
    };
  },
};
</script>

<style scoped lang="scss">
.order {
  font-size: 14px;
  padding-bottom: 5rem;
  .mfcList {
    width: 60%;
    height: 2rem;
    border: 1px solid #a9a9a9;
  }
  :deep(.nut-cell__title) {
    align-items: flex-start;
    font-size: 15px;
    font-weight: 500;
  }
  :deep(.nut-cell__value) {
    font-size: 15px;
    font-weight: 500;
    color: #666 !important;
  }
  .nut-pagination {
    margin-left:20px;
  }



  .nut-tabpane {
    width: 100%;
    flex-shrink: 0;
    display: block;
    background-color: #fff;
    padding: 0.5rem;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .orderList {
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    background: #f4f4f4;
    border-radius: 0.5rem;
  }
  .orderList .mfcName {
    float: left;
    margin-left: .5rem;
  }
  .orderList .time {
    margin: 0 1rem;
  }
  .orderList .orderStatus {
    float: right;
    margin-right: .5rem;
  }
  .clearBox {
    clear:both;
  };

  .orderList .goodsList .goods {
    margin: 0.5rem 1rem;
    background-color: #ffffff;
  }

  .orderList .goodsList .goods .desc{
    height: 5rem;
    margin-left: 0.5rem;
  }  
  .orderList .goodsList .goods .desc .goodsName{
    height: 2.5rem;
    line-height: 2.5rem;
    align-items: center;
  }
  .orderList .goodsList .goods .desc .goodsDesc{
    height: 2.5rem;
    line-height: 2.5rem;
  }
  
  .orderList .goodsList .goods .goodsImg {
    height: 5rem;
    width: 5rem;
  }
  .payFee{
    text-align: right;
  }
}
</style>
